import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { get, flatten } from 'lodash';
import TimesheetDailySnapshot from './TimesheetDailySnapshot';
import TextLoader from '../../common/components/TextLoader';
import { GET_TIMESHEET_WEEKLY_SNAPSHOT } from '../queries';
import { fetchTimesheetsForPeriod } from '../index';

const mapStateToProps = state => ({
  user: state.meteor.user,
});

export const sumLineItemHours = li => {
  const mon = get(li, 'monTask.hours') || 0;
  const tue = get(li, 'tueTask.hours') || 0;
  const wed = get(li, 'wedTask.hours') || 0;
  const thu = get(li, 'thuTask.hours') || 0;
  const fri = get(li, 'friTask.hours') || 0;
  const sat = get(li, 'satTask.hours') || 0;
  const sun = get(li, 'sunTask.hours') || 0;
  return mon + tue + wed + thu + fri + sat + sun;
};

const formatDate = dateStr => {
  let dateString = dateStr;
  let year = dateString.substring(0, 4);
  let month = dateString.substring(4, 6);
  let day = dateString.substring(6, 8);
  let date = new Date(year, month - 1, day);
  return date.toLocaleDateString('fr-CA');
};

export const createGridData = timesheets =>
  flatten(
    timesheets.map(ts =>
      ts.lineItems.map(li => {
        let bu = `${get(li, 'project.accountingCode') || ''}-${
          get(li, 'project.accountingCodeSuffix') || ''
        }`;
        if (get(li, 'deliverable.accountingCode') && get(li, 'deliverable.accountingCodeSuffix')) {
          bu = `${get(li, 'deliverable.accountingCode') || ''}-${
            get(li, 'deliverable.accountingCodeSuffix') || ''
          }`;
        }
        let days = ['monTask', 'tueTask', 'wedTask', 'thuTask', 'friTask', 'satTask', 'sunTask'];
        let finalData = [];
        for (let i = 0; i < days.length; i++) {
          const role = get(ts, 'user.role');
          const projectRoles = get(li, 'project.roles') || [];
          const rate = projectRoles.find(r => r.name === role)?.rate;

          let temp = {
            timesheetId: get(ts, '_id'),
            status: get(ts, 'status'),
            endDate: moment(get(ts, 'endDate')).format('MM-DD-YYYY'),
            name: `${get(ts, 'user.firstName')} ${get(ts, 'user.lastName')}`,
            role,
            rate,
            payrollId: get(ts, 'user.payrollId'),
            email: get(ts, 'user.emails[0].address'),
            clientName: get(li, 'project.client.shortName'),
            project: get(li, 'project.name'),
            projectCode: get(li, 'project.projectCode'),
            roles: get(li, 'project.roles')?.length || 0,
            bu: bu,
            milestone: get(li, 'deliverable.milestoneName'),
            deliverable: get(li, 'deliverable.deliverableName'),
          };
          let taskdate = get(li, days[i] + '.date');
          let taskhour = get(li, days[i] + '.hours');
          let comment = get(li, days[i] + '.comment');
          if (taskdate && taskhour) {
            temp['taskdate'] = formatDate(taskdate);
            temp['hours'] = taskhour;
            temp['comment'] = comment;

            if (rate) {
              const totalCost = rate * taskhour;
              temp['totalCost'] = totalCost;
            }

            finalData.push(temp);
          }
        }
        return finalData;
      }),
    ),
  );

const TimesheetListContainer = ({ user, setLoading, loading, startDate, endDate, ...rest }) => {
  const [timesheets, setTimesheets] = useState([]);
  const [loadingText, setLoadingText] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const fetchedData = await fetchTimesheetsForPeriod({
        startDate,
        endDate,
        setLoadingText,
        query: GET_TIMESHEET_WEEKLY_SNAPSHOT,
      });
      setTimesheets(fetchedData);
      setLoading(false);
    };

    fetchData().catch(console.error);
  }, [startDate, endDate, setLoading]);

  const timesheetsWithTrimmedTasks = timesheets.map(timesheet => {
    const newLineItems = timesheet.lineItems.map(lineItem => {
      for (const property in lineItem) {
        if (property.endsWith('Task') && lineItem[property]) {
          const { date } = lineItem[property];
          if (date < startDate || date > endDate) {
            delete lineItem[property];
          }
        }
      }
      return lineItem;
    });
    return {
      ...timesheet,
      lineItem: newLineItems,
    };
  });

  const IndividualTaskrow = createGridData(timesheetsWithTrimmedTasks);
  const rowData = IndividualTaskrow.flat();

  if (loading) {
    return <TextLoader text={loadingText} />;
  }

  return <TimesheetDailySnapshot user={user} rowData={rowData} endDate={endDate} {...rest} />;
};

export default connect(mapStateToProps)(TimesheetListContainer);
